import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    faMoneyBillWave,
    faUserEdit,
    faCalendarAlt,
    faCog,
    faHandHoldingUsd,
    faClock,
    faTools,
    faBan,
    faUser,
    faArrowLeft,
    faArrowRight,
    faCheck,
    faCheckCircle,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'app/core/services/auth/auth.service';
import { Operacion } from 'app/core/services/auth/Operacion';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { RestriccionesService } from 'app/core/services/auth/restricciones.service';
import { PropuestaComercialComponent } from 'app/shared/propuesta-comercial/propuesta-comercial.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EstacionesModalComponent } from '../../partials/modals/estaciones-modal/estaciones-modal.component';
import { SolicitarOpinionComponent } from 'app/shared/partials/propuestas/solicitar-opinion/solicitar-opinion.component';
import { Subscription } from 'rxjs';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DevolverOperacionModalComponent } from 'app/modules/aplicacion-evaluacion-riesgos/modals/devolver-operacion-modal/devolver-operacion-modal.component';
import { ToastService } from 'app/core/services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'app/app.service';
import { ParametrosService } from 'app/core/services/configuration/parametros.service';
import { ConfirmModalComponent } from "app/shared/utils/confirm-modal/confirm-modal.component";
import { CommitteeComponent } from 'app/shared/partials/propuestas/forms/committee/committee.component';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { SharedFunctions } from 'app/core/shared/functions';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-completar-solicitud',
    templateUrl: './completar-solicitud.component.html',
    styleUrls: ['./completar-solicitud.component.css']
})
export class CompletarSolicitudComponent implements OnInit, OnDestroy {
    faTimes = faTimes
    active = 1;
    faBan           = faBan;
    faCheckedCircle = faCheckCircle;
    faUser          = faUser;
    faArrowLeft     = faArrowLeft;
    faArrowRight    = faArrowRight;
    faCheck         = faCheck;
    faMoneyBillWave = faMoneyBillWave;
    faUserEdit = faUserEdit;
    faCalendarAlt = faCalendarAlt;
    faCog = faCog;
    faHandHoldingUsd = faHandHoldingUsd;
    faClock = faClock;
    faTools = faTools;
    formularioEscalar: FormGroup;
    proceso: any;
    cabecera: any = {};
    _operacionDetalles: any[] = [];
    operacionAceptantes: any[] = [];
    operacionCancelada: boolean = false;
    tieneDeudaVencida = false
    _deudoresRucs = []

    set operacionDetalles(value) {
        // console.log('Operacion Detalles desde General');
        // console.log(value);
        if (value) {
            this._operacionDetalles = value
            this.operacionAceptantes = value.reduce((acc, item) => {
                let { deudor, deudor_nombre, deudor_ruc } = item
                let result = acc.filter((accitem) => accitem.deudor == deudor)
                if (!(result.length > 0)) {
                    acc.push({
                        deudor: deudor,
                        deudor_nombre: deudor_nombre,
                        deudor_ruc: deudor_ruc,
                    })
                }
                return acc
            }, [])
        }
    }
    get operacionDetalles() {
        return this._operacionDetalles
    }
    documentosMapped: any[] = [];
    readOnly: boolean = false;
    comites: any = [];
    comitesAsignables: any = [];
    showCavali = false;
    showReprogramacionCavali = false;
    /**
     * readOnlyDetalles No puede modificar Documentos Detalle
     * readOnlyCheckListDocs No puede modificar la Carga de Documentos
     * readOnlyPropuestaComercial No puede modificar el formulario de Propuesta comercial
     * readOnlyPropuestaRiesgo No puede modificar el formulario de Propuesta de Riesgo
     * readOnlyPropuestaComite No puede modificar el formulario de Propuesta de Comite
     */
    readOnlyDetalles: boolean = false;
    readOnlyCheckListDocs: boolean = false;
    readOnlyPropuestaComercial: boolean = false;
    readOnlyPropuestaRiesgo: boolean = false;
    readOnlyPropuestaComite: boolean = false;
    readOnlyBotonDerivar: boolean = false;
    readOnlyBotonDevolver: boolean = false;
    readOnlyBotonReenviar: boolean = false;
    readOnlyBotonLiquidacion: boolean = true;
    readOnlyLegal: boolean = true;
    botonAcciones = true;
    botonDerivar = true;
    botonAprobar = false;
    botonRefrescar = false;
    botonDevolver = true;
    botonReenviar = false;
    operacion: Operacion;
    user: any;
    formularioValido: boolean = false
    _idProceso: number;
    procesoBandejaResumen: any;

    clienteFactoring: any;
    set idProceso(value) {
        
        // console.log('Operacion ID');
        // console.log(value);

        let resumen = this.factoring.operacionResumen(value)
        let bandejaOperacion = this.factoring.obtenerOperacionBandejaUnico(value)
        let cabeceraInt = this.docs.obtenerCabecera(value)
        let operacion = this.factoring.obtenerOperacion(value)
        let comites = this.factoring.obtenerComites()
        let propuesta = this.propuestaService.obtenerPropuestaComite(value);

        // console.log('Operacion Cabecera desde General');
        // console.log(cabeceraInt);

        Promise.all([
            resumen, //0
            cabeceraInt, //1
            operacion, //2
            comites, //3
            bandejaOperacion, //4
            propuesta //5
        ]).then((proceso: any[]) => {
            this.proceso                   = proceso[0];
            this.cabecera                  = proceso[1]
            this.operacion                 = new Operacion(proceso[2])
            this.operacionDetalles         = this.operacion.detalle
            this.operacion.setResumen(proceso[0])
            this.operacion.vuelta_adelanto = proceso[4].vuelta_adelanto;
            this.initReadOnly(this.operacion)
            this.comites                   = proceso[3].results
            this.comitesAsignables         = this.comites.filter((comite) => comite.id != this.operacion.comite)
            this.operacion.revisado        = proceso[4].revisado
            this.operacionCancelada        = proceso[4].estado == 11
            this.procesoBandejaResumen     = proceso[4]
            this.restricciones.listaAprobaciones = proceso[5]['comite_aprobaciones']

            if (this.operacion.operacion.tipo_linea_solicitada == 2) {
                this.factoring.getBeneficiarios(this.operacion.operacion.beneficiario_factoring).then(res => {
                    this.clienteFactoring = res
                })
            }

            let reprogramacion = this.operacionDetalles.reduce((r, e) => r || (e.transferencia_contable && (e.vencimiento_cavali_rep || e.neto_pagar_rep) && !e.reprogramacion_cavali), false)
            this.showCavali = this.isShowCavali();
            this.showReprogramacionCavali = this.isShowCavali() && reprogramacion;

            return this.factoring.clienteRelaciones(this.proceso.beneficiario.id)

        }).then((relaciones: any) => {
            let relaciones_ = relaciones.results
            let resultados = this.operacionAceptantes.map((aceptante) => {
                let index = relaciones_.findIndex((relacion) => {
                    return relacion.ruc === aceptante.deudor_ruc
                })
                if (index == -1) {
                    aceptante['resaltar'] = false
                    relaciones_.push(aceptante)
                } else {
                    aceptante['resaltar'] = true
                    relaciones_[index]['resaltar'] = true
                }
            })
            this.operacionAceptantes = relaciones_.map((aceptante) => {
                if (aceptante.nombre) {
                    aceptante.deudor_nombre = aceptante.nombre
                    aceptante.deudor = aceptante.id
                    aceptante.deudor_ruc = aceptante.ruc
                }

                if (!(!!aceptante.resaltar)) {
                    aceptante.resaltar = false
                }

                return aceptante
            })
        })

        this._idProceso = value
    }

    @ViewChild(PropuestaComercialComponent) propuestaComponent: PropuestaComercialComponent;
    @ViewChild(SolicitarOpinionComponent) solicitarOpinionComponent: SolicitarOpinionComponent;
    @ViewChild('e_tipocambio', { static: false }) public e_tipocambio;
    @ViewChild('escalarModal', { static: false }) public escalarModal;

    private changeOperationRef: Subscription = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private docs: DocschecklistService,
        private router: Router,
        private factoring: FactoringService,
        private propuestaService: PropuestaComercialService,
        private authService: AuthService,
        public restricciones: RestriccionesService,
        public localService: LocalServiceService,
        public modalService: NgbModal,
        public appService: AppService,
        public spinner: NgxSpinnerService,
        private formbuild: FormBuilder,
        public toast: ToastService,
        private parametrosService: ParametrosService,
    ) {
        this.user = this.authService.user;
        this.activatedRoute.params.subscribe(param => {
            this.idProceso = param.id
        })
    }

    ngOnInit(): void {
        this.changeOperationRef = this.factoring.changeOperacionEnd$.subscribe(() => {
            this.getProceso();
        });
    }

    ngOnDestroy() {
        this.changeOperationRef.unsubscribe();
    }

    spinnerOff(mensaje: string = null, ok: boolean = true) {
        this.spinner.hide()
        this.appService.notifyMe(mensaje, ok)

        if (mensaje && ok)
            this.toast.success(mensaje)
        if (mensaje && !ok)
            this.toast.warning(mensaje)
    }

    getProceso() {
        this.factoring.operacionResumen(this._idProceso)
            .then(res => {
                this.operacion.setResumen(res);
            })
            .catch(error => {
                console.error(error);
            });
    }

    getDetalles(detalles: any) {
        /**
         * Si viene null, es para refrescar
         */
        if (detalles == null) {
            this.factoring.obtenerDetalles(this.operacion.id)
                .then((res: any[]) => this.operacionDetalles = res)
                .catch(err => this.displayErrors(err));
            return;
        }

        // Si el parametro {del} viene en TRUE es un evento de eliminacion
        if (detalles.del) {
            this.factoring.eliminarDetalle(detalles.id)
                .then(res => this.factoring.obtenerDetalles(this.operacion.id))
                .then((res: any) => {
                    this.operacion.detalle = res.results

                    // Aquí llama al componente para actualizarlo luego de un delete detail (Se actualiza cabecera)
                    let cabeceraInt = this.docs.obtenerCabecera(this.operacion.id)
                    Promise.all([
                        cabeceraInt
                    ]).then((proceso: any[]) => {

                        this.cabecera = proceso[0]
                    })
                    
                })
                .catch(err => this.displayErrors(err))
            return
        }

        // Si el parametro {invalido} viene en TRUE es un evento de invalidacion (no se tiene en cuenta para validaciones de igual numero_documento)
        if ('invalido' in detalles) {
            this.factoring.invalidarDetalle(detalles.id, detalles['invalido'])
                .then(res => this.factoring.obtenerDetalles(this.operacion.id))
                .then((res: any) => {
                    this.operacion.detalle = res.results
                })
                .catch(err => this.displayErrors(err))
            return
        }

        // Si es una operacion de CREACIÓN O EDICION se valida en la siguiente sentencia
        // En cualquier caso se agrega el ID del proceso al objeto
        detalles.operacion = this.operacion.id
        let request;
        if (detalles.id)
            // Si es un evento de EDITAR, envía el ID del detalle al servicio de factoring
            request = this.factoring.guardarDetalle(detalles, detalles.id)
        else
            // Si es un evento de CREACIÓN no envia el ID para ser enviado vía POST
            request = this.factoring.guardarDetalle(detalles)


        request.then(res => this.factoring.obtenerDetalles(this.operacion.id))
            .then(res => {
                this.operacion.detalle = res.results

                // Actualiza cabecera
                let cabeceraInt = this.docs.obtenerCabecera(this.operacion.id)
                Promise.all([
                    cabeceraInt
                ]).then((proceso: any[]) => {

                    this.cabecera = proceso[0]
                })
            })
            .catch(err => this.displayErrors(err))
    }

    displayErrors(err) {
        if (err && err.error) {
            for (let prop in err.error) {
                this.toast.warning(err.error[prop])
            }
        }
    }

    initOperacion() {
        this.router.navigate(['/operaciones']);
    }

    async derivarOperacion() {

        if (this.operacion.estacion == this.operacion.estacionOficialNegocioLiquidacion) {
            const validarUbigeo = await this.validacionMoneda();
            if (!validarUbigeo) return;
        }

        let queryParams = {}
        /*
         * No mostrar modal, para hacer acción por defecto: enviar a facturar
        if (this.operacion.estacion == this.operacion.estacionTesoreria) {
            const paramModalEnvioAutomatico = await this.parametrosService.obtenerParametro('FACTURACION_MODAL_ENVIO_AUTOMATICO').toPromise()
            if (paramModalEnvioAutomatico.valor == '1') {
                const enviarFacturasSunatResult = await Swal.fire({
                    title: 'Enviar facturas a SUNAT',
                    text: "Las facturas generadas se enviarán a SUNAT de manera automática",
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085D6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, enviar',
                    cancelButtonText: 'No enviar',
                    allowOutsideClick: false,
                })
                queryParams['enviar_facturar'] = enviarFacturasSunatResult.isConfirmed ? 1 : 0;
            }
        }
        */

        this.factoring.derivarOperacion(this.operacion.id, queryParams).then((res) => {
          this.router.navigate(['/operaciones'])
        }).catch((err) => {
            console.log(err)
        })
    }

    async validacionesDerivacion() {

        this.factoring.validardocumentosVencidos(this.operacion.id).then((res: any) => {
            if (res.documentos && res.documentos.length > 0) {
                this.lanzarModalConfirmacion(res.documentos)
            } else {
                this.derivarOperacion()
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    async validacionMoneda() {
        const resp = await this.factoring.validaUbigeoDetalle(this.operacion.id).then((res: any) => {
            if (!res.estado) {
                this.toast.warning(res.mensaje)
                return false;
            }

            return true;
        });

        return resp;
    }

    solicitarMotivoDevolucion(estacion) {
        const modalRef = this.modalService.open(DevolverOperacionModalComponent, {

        });
        modalRef.result.then((result) => {
        }, (reason) => {
        });
        modalRef.componentInstance.successEmit.subscribe((data) => {
            modalRef.dismiss()
            let devolverOperacionData = {
                estacion,
                motivo_devolucion: data.motivo,
                comentario_devolucion: data.comentario
            }
            this.devolverOperacion(devolverOperacionData);
        });
    }

    async devolverOperacion(data) {
        this.factoring.devolverOperacion(this.operacion.id, data)
            .then(result => {
                this.initOperacion();
            })
            .catch(error => {
                console.error(error);
            });
    }

    devolverEstacion() {
        switch (this.operacion.estacion) {
            case 1:
                /**
                 * Devolver a estacion cliente
                 */
                break;
            case this.operacion.estacionOficialDeNegocio:
                /**
                 * Devolver a estacion Oficial
                 */
                //  this.solicitarMotivoDevolucion(this.operacion.estacionCliente);
                this.openModalEstaciones([this.operacion.estacionCliente]);
                break;
            case this.operacion.estacionAnalisisRiesgo:
                /**
                 * Devolver a estacion oficial o analista
                 */
                //  this.solicitarMotivoDevolucion(this.operacion.estacionOficialDeNegocio);
                this.openModalEstaciones([this.operacion.estacionOficialDeNegocio]);
                break;
            case this.operacion.estacionComiteCreditos:
                /**
                 * Devolver a comite
                 */
                this.openModalEstaciones([this.operacion.estacionOficialDeNegocio, this.operacion.estacionAnalisisRiesgo]);
                break;
            case this.operacion.estacionOficialNegocioPostComite:
                /**
                 * Devolver a Oficial post comite
                 */
                //  this.solicitarMotivoDevolucion(this.operacion.estacionComiteCreditos);
                this.openModalEstaciones([this.operacion.estacionComiteCreditos]);
                break;
            case this.operacion.estacionLegal:
                /**
                 * Devolver a Asesor legal o Oficial post comite
                 */
                //  this.solicitarMotivoDevolucion(this.operacion.estacionOficialNegocioPostComite);
                this.openModalEstaciones([this.operacion.estacionOficialNegocioPostComite]);
                break;
            case this.operacion.estacionAsesorLegal:
                // ESTACION ELIMINADA, TODO QUEDA COMO ESTACION LEGAL
                /**
                 * Devolver a Liquidación o Asesor legal o Comite
                 */
                this.factoring.devolverOperacion(this.operacion.id, { estacion: this.operacion.estacionOficialNegocioPostComite })
                    .then(result => {
                        this.initOperacion();
                    })
                    .catch(error => {
                        console.error(error);
                    });
                break;
            case this.operacion.estacionOficialNegocioLiquidacion:
                /**
                 * Devolver a Oficial Liquidación
                 */
                let estacionesDevolucion = []
                if (this.operacion.sin_riesgo && !this.operacion.vuelta_adelanto) {
                    estacionesDevolucion = [this.operacion.estacionAnalisisRiesgo]
                } else {
                    estacionesDevolucion = [this.operacion.estacionComiteCreditos, this.operacion.estacionLegal]
                }
                this.openModalEstaciones(estacionesDevolucion);
                break;
            case this.operacion.estacionJefeOperaciones:
                /**
                 * Devolver a Oficial Liquidación
                 */
                //  this.solicitarMotivoDevolucion(this.operacion.estacionOficialNegocioLiquidacion);
                this.openModalEstaciones([this.operacion.estacionOficialNegocioLiquidacion]);
                break;
            case this.operacion.estacionTesoreria:
                /**
                 * Devolver a Tesorería
                 */
                // this.factoring.devolverOperacion(this.operacion.id, { estacion: this.operacion.estacionOficialNegocioLiquidacion })
                //     .then(result => {
                //         this.initOperacion();
                //     })
                //     .catch(error => {
                //         console.error(error);
                //     });
                this.openModalEstaciones([this.operacion.estacionJefeOperaciones, this.operacion.estacionOficialNegocioLiquidacion]);
                break;
            case this.operacion.estacionAsistenteCobranzas:
                // this.solicitarMotivoDevolucion(this.operacion.estacionTesoreria);
                this.openModalEstaciones([this.operacion.estacionTesoreria]);
                break;
        }
    }

    openModalEstaciones(estaciones = []) {
        const modalRef = this.modalService.open(EstacionesModalComponent, {

        });

        modalRef.componentInstance.restricciones = estaciones;

        modalRef.componentInstance.estacionEmit.subscribe(
            result => {
                // this.solicitarMotivoDevolucion(result.estacion);
                modalRef.dismiss();
                let devolverOperacionData = {
                    estacion: result.estacion,
                    motivo_devolucion: result.motivo,
                    comentario_devolucion: result.comentario
                }
                this.devolverOperacion(devolverOperacionData);
            }
        );

        modalRef.result.then((result) => {
        }, (reason) => {
        });
    }

    async reenviarEstacion() {
        if (this.operacion.estacion == this.operacion.estacionOficialNegocioLiquidacion) {
            const validarUbigeo = await this.validacionMoneda();
            if (!validarUbigeo) return;
        }

        this.factoring.reenviarOperacion(this.operacion.id, { estacion: this.operacion.estacion_devolucion })
            .then(result => {
                this.initOperacion();
            })
            .catch(error => {
                console.error(error);
            });
    }

    hiddeButtons() {
        this.botonAcciones = false;
    }

    initReadOnly(operacion) {

        if (operacion.isDenegado) {
            this.readOnlyToggle(true);
            return;
        }

        if (operacion.estacion_devolucion) {
            this.botonReenviar = true;
            this.botonDerivar = false;
            this.botonDevolver = true;
        } else {
            this.botonReenviar = false;
            this.botonDerivar = true;
            this.botonDevolver = this.botonDerivar;
        }

        if (operacion.actualOficialNegocioLiquidacion && operacion.vuelta_adelanto) {
            this.botonDevolver = false;
        }

        if (operacion.actualAsistenteCobranzas) {
            this.botonDerivar = false;
        }

        switch (this.user.perfil) {
            case this.authService.perfil.perfilLiderDeEquipo:

                /**
                 * Perfil Analista Oficial de Negocio
                 * Si no esta en su estacion, no puede moficiar nada
                 * Si esta en su setacion puede modificar la propuesta de riesgo ni la propuesta de comite
                 * Estacion oficial de negocio
                 */
                let equipo = this.authService.miembrosDeleQuipo
                let habilitarEdicionDeOficial = operacion.isResponsable(this.authService.user) || operacion.isResponsableMiembroEquipo(equipo)
                if (operacion.actualOficialNegocio && habilitarEdicionDeOficial) {
                    this.readOnlyToggle(false);
                    this.readOnlyPropuestaRiesgo = true;
                    this.readOnlyPropuestaComite = true;
                    // this.readOnlyDetalles = false;
                } else if (operacion.estacionOficialNegocioPostComite && habilitarEdicionDeOficial) {
                    this.readOnlyToggle(true);
                    this.readOnlyCheckListDocs = false;
                    this.readOnlyBotonDerivar = false;
                    // this.readOnlyDetalles = false;
                } else if (operacion.actualOficialNegocioLiquidacion && habilitarEdicionDeOficial) {
                    this.readOnlyToggle(true);
                    this.readOnlyCheckListDocs = false;
                    this.readOnlyBotonDerivar = false;
                    this.readOnlyBotonLiquidacion = false;
                } else {
                    this.readOnlyToggle(true);
                    this.hiddeButtons()
                }

                this.readOnlyDetalles = !operacion.habilitarEdicionDetalles;

            break;
            case this.authService.perfil.perfilOficialDeNegocioID:
            case this.authService.perfil.perfilAsistenteComercialID:
                /**
                 * Perfil Analista Oficial de Negocio
                 * Si no esta en su estacion, no puede moficiar nada
                 * Si esta en su setacion puede modificar la propuesta de riesgo ni la propuesta de comite
                 * Estacion oficial de negocio
                 */
                if (operacion.actualOficialNegocio && operacion.isResponsable(this.authService.user)) {
                    this.readOnlyToggle(false);
                    this.readOnlyPropuestaRiesgo = true;
                    this.readOnlyPropuestaComite = true;
                    // this.readOnlyDetalles = false;
                } else if (operacion.estacionOficialNegocioPostComite && operacion.isResponsable(this.authService.user)) {
                    this.readOnlyToggle(true);
                    this.readOnlyCheckListDocs = false;
                    this.readOnlyBotonDerivar = false;
                    // this.readOnlyDetalles = false;
                } else if (operacion.actualOficialNegocioLiquidacion && operacion.isResponsable(this.authService.user)) {
                    this.readOnlyToggle(true);
                    this.readOnlyCheckListDocs = false;
                    this.readOnlyBotonDerivar = false;
                    this.readOnlyBotonLiquidacion = false;
                } else {
                    this.readOnlyToggle(true);
                    this.hiddeButtons()
                }

                this.readOnlyDetalles = !operacion.habilitarEdicionDetalles;

            break;
            case this.authService.perfil.perfilGerenciaGeneralID:
            case this.authService.perfil.perfilGerenciaComercialID:
                /**
                 * Perfil Gerente Comercial
                 * Solo puede leer, pero no moficiar
                 */
                this.readOnlyToggle(true)
                this.readOnlyPropuestaComercial = false
                this.readOnlyCheckListDocs = false
                // this.readOnlyDetalles = false
                this.readOnlyBotonDerivar = false

                this.readOnlyDetalles = ((this.authService.user.isGerenteGeneral || this.authService.user.isGerenteComercial) && this.operacion.estacionJefeOperaciones) ? false : !operacion.habilitarEdicionDetalles;

                // if (operacion.actualAnalisisRiesgo && operacion.estacion_devolucion) {
                //     this.botonReenviar = false;
                // }
                // if (operacion.estacion == 4 || operacion.estacion == 8) {
                //     this.readOnlyPropuestaComercial = true;
                // }
                if (operacion.estacion != 2) {
                    this.readOnlyPropuestaComercial = true;
                }
                if (operacion.estacion === 8) { // estacion liquidacion es 8
                    this.readOnlyLegal = false;
                }

                break;
            case this.authService.perfil.perfilAnalistaRiesgosID:
                /**
                * Perfil Analista de Riesgo
                * Si no esta en su estacion, no puede moficiar nada
                */
                if (operacion.actualAnalisisRiesgo) {
                    this.readOnlyToggle(true)
                    this.readOnlyPropuestaRiesgo = false
                    this.readOnlyCheckListDocs = false
                    this.readOnlyBotonDerivar = false;
                } else {
                    this.readOnlyToggle(true)
                    this.hiddeButtons()
                }
                break;
            case this.authService.perfil.perfilAsesorLegalID:
                /**
                 * Asesor Legal
                 */
                this.readOnlyToggle(true)
                this.readOnlyCheckListDocs = false;
                this.readOnlyBotonDerivar = false;
                this.readOnlyLegal = false;
                // if (operacion.actualLegal && operacion.isResponsable(this.authService.user)) {
                //     this.readOnlyToggle(true)
                //     this.readOnlyCheckListDocs = false
                //     this.readOnlyBotonDerivar = false;
                //     this.readOnlyLegal = false;
                // } else {
                //     this.readOnlyToggle(true)
                //     this.hiddeButtons()
                // }

                break;
            case this.authService.perfil.perfilLegalID:
                /**
                 * Legal
                 */
                if (operacion.actualLegal && operacion.isResponsable(this.authService.user)) {
                    this.readOnlyToggle(true)
                    this.readOnlyCheckListDocs = false
                    this.readOnlyBotonDerivar = false;
                    this.readOnlyLegal = false;
                } else {
                    this.readOnlyToggle(true)
                    this.hiddeButtons()
                }
                break;
            case this.authService.perfil.perfilJefeDeOperacionesID:
                /**
                 * Jefe de operaciones
                 */
                this.readOnlyToggle(true)
                if (operacion.actualJefeOperaciones) {
                    this.readOnlyCheckListDocs = false;
                    this.readOnlyBotonDerivar = false;
                    this.readOnlyDetalles = false;
                } else {
                    this.hiddeButtons()
                }
                break;

            case this.authService.perfil.perfilAnalistaOperacionesID:
                /**
                 * Jefe de operaciones
                 */

                this.readOnlyToggle(true)
                if (operacion.actualJefeOperaciones && operacion.isAnalistaOperacion(this.authService.user)) {
                    this.readOnlyCheckListDocs = false;
                    this.readOnlyLegal = false;
                    this.readOnlyDetalles = false;
                    this.readOnlyBotonDerivar = false;
                    this.readOnlyBotonDevolver = false;
                    this.readOnlyBotonReenviar = false
                } else {
                    this.hiddeButtons()
                }
                break;
            case this.authService.perfil.perfilAsistenteCobranzasID:
                /**
                 * Cobranzas
                 */
                this.readOnlyToggle(true);
                if (operacion.actualAsistenteCobranzas && operacion.isAsistenteCobranzas(this.authService.user) && operacion.estado < 8) {
                    this.readOnlyCheckListDocs = false;
                    this.readOnlyBotonDerivar = false;
                    this.readOnlyBotonDevolver = false;
                } else {
                    this.botonDerivar = false;
                    this.hiddeButtons()
                }
                break;
            case this.authService.perfil.perfilRecepcionID:
                /**
                * Recepcionista
                */
                this.readOnlyToggle(true)
                if (operacion.actualRecepcion && operacion.isResponsable(this.authService.user)) {
                    this.readOnlyCheckListDocs = false
                    this.readOnlyBotonDerivar = false;
                }
                break;
            case this.authService.perfil.perfilTesoreriaID:
                /**
                 * Tesoreria
                 */
                this.readOnlyToggle(true)
                if (operacion.estacionTesoreria && operacion.isResponsable(this.authService.user)) {
                    this.readOnlyBotonDerivar = false;
                } else {
                    this.hiddeButtons()
                }

                break;
            case this.authService.perfil.perfilLiderDeTesoreriaID:
                /**
                 * Tesoreria
                 */
                this.readOnlyToggle(true)
                if (operacion.estacionTesoreria) {
                    this.readOnlyBotonDerivar = false;
                } else {
                    this.hiddeButtons()
                }

                break;
            case this.authService.perfil.perfilJefeDeAdmisionID:
                /**
                 * Jefe de Admision
                 */
                if (operacion.actualAnalisisRiesgo) {
                    this.readOnlyToggle(true)
                    this.readOnlyPropuestaRiesgo = false
                    this.readOnlyCheckListDocs = false
                    this.readOnlyBotonDerivar = false;
                } else {
                    this.readOnlyToggle(true)
                    this.hiddeButtons()
                }

                break;
            case this.authService.perfil.perfilConsultaID:
                this.readOnlyToggle(true)
                this.hiddeButtons()
                break;
            default:
                this.readOnlyToggle(true)
                break;
        }

        if (this.authService.perfil.isGerenteGeneral) {
            this.enableComiteStation();
        } else if (this.authService.user.comite.length < 1) {
            this.authService.comiteIntegrantes(this.authService.user.id).then((res: any) => {
                if (operacion.actualComiteCreditos && this.authService.user.comite.length > 0) {
                    this.enableComiteStation();
                }
            })
        } else if (operacion.actualComiteCreditos && this.authService.user.comite.length > 0) {
            this.enableComiteStation();
        }
    }

    readOnlyToggle(onlyRead) {
        this.readOnlyDetalles           = onlyRead
        this.readOnlyCheckListDocs      = onlyRead
        this.readOnlyPropuestaComercial = onlyRead
        this.readOnlyPropuestaRiesgo    = onlyRead
        this.readOnlyPropuestaComite    = onlyRead
        this.readOnlyBotonDerivar       = onlyRead
    }

    enableComiteStation() {
        let comite = this.authService.user.comite.find((comite) => comite.comite === this.operacion.comite)
        if ((this.authService.user.comite.length > 0 && comite) || this.authService.perfil.isGerenteGeneral) {
            this.readOnlyCheckListDocs = false
            this.botonDerivar = false;
            this.botonAprobar = true;
            this.readOnlyBotonDerivar = false;
            this.readOnlyPropuestaComite = false;
            this.botonAcciones = true;
        } else {
            this.botonDerivar = false;
            this.readOnlyBotonDerivar = true;
            this.botonAprobar = false;
            this.readOnlyPropuestaComite = true;

        }
    }

    aprobarOperacion() {
        this.factoring.aprobarOperacion(this.operacion).then((res) => {
            this.propuestaComponent.updateComite();
            this.router.navigate(['/operaciones-comite']);
        })
    }

    denegarOperacion() {
        this.factoring.denegarOperacion(this.operacion).then((res) => {
            this.router.navigate(['/operaciones-comite'])
        })

        return
    }

    habilitarOperacion() {
        this.factoring.habilitarOperacion(this.operacion).then((res) => {
            this.router.navigate(['/denegados'])
        })
    }

    solicitarOpinion() {
        this.solicitarOpinionComponent.initModal(this.operacion.id)
    }

    formularioComiteValido(evento) {
    }

    async importXML(event: File[]) {
        // let data = event;
        // data.append('operacion', this.operacion.id);
        // this.subirXml(data);
        let data = [];
        for (let index = 0; index < event.length; index++) {
            let form = new FormData();
            form.append('operacion', this.operacion.id);
            form.append("adjunto", event[index]);
            form.append("peso", event['size']);
            data.push(form);
        }

        await this.subirXml(data);
        // this.refreshTable();
    }

    async subirXml(data) {
        await this.factoring.importarXmlDetalles(data)
            .then(result => {
                return this.factoring.obtenerOperacion(this.operacion.id);
                // return result;
            })
            .then((res: Operacion) => this.operacion = new Operacion(res))
            .catch((err: any) => {
                var error: any;
                if (err.error['deudor'] && err.error['deudor'].length > 0) {
                    err.error['deudor'].forEach((item) => {
                        this.spinnerOff(item, false);
                    })
                }
                else if (err.error['cliente'] && err.error['cliente'].length > 0) {
                    err.error['cliente'].forEach((item) => {
                        this.spinnerOff(item, false);
                    })
                } else if (err.error['linea_disponible']) {
                    error = err.error['linea_disponible']
                } else {
                    // el error ya no es necesario, el error lo tiene el 'factoring.service'
                    // error = 'Ocurrió un error al intentar cargar el archivo XML';
                }
                this.spinnerOff(error, false);
            });
    }

    refreshTable() {
        return this.factoring.obtenerOperacion(this.operacion.id)
            .then((res: Operacion) => this.operacion = new Operacion(res))
            .catch(error => {
                console.error(error);
            });
    }

    escalarComite() {

        this.formularioEscalar = this.formbuild.group({
            comiteescalar: [null],
        });


        this.modalService.open(this.escalarModal, {
            size: 'md'
        }).result.then((response: any) => {
            if (response) {
                return this.factoring.actualizarPropiedadOperacion(this.operacion.id, { comite: this.formularioEscalar.get('comiteescalar').value })
            }
        }).then((res: any) => {
            this.router.navigate(['/operaciones-comite']);
            this.getProceso()
        })
    }

    get isGerenciaOrOficial() {
        return (this.operacion.actualOficialNegocioLiquidacion && (this.user.isGerenteComercial || this.user.isGerenteGeneral || this.user.isOficialDeNegocio));
    }

    lanzarModalConfirmacion(documentos: any[]) {
        const modalRef = this.modalService.open(ConfirmModalComponent, {});
        let mensaje = `
            <span><strong>Existen documentos vencidos en la operación actual:</strong></span>
            <ul>
        `

        documentos.forEach((item) => {
            mensaje += `<li>${item.documento_descripcion}</li>`
        })
        mensaje += `</ul><br><span class='message'><strong>¿Está seguro de que desea derivar la operación?</strong></span>`
        modalRef.componentInstance.title = "Documentos Vencidos";
        modalRef.componentInstance.message = mensaje;
        modalRef.componentInstance.messageHTML = true;

        modalRef.result.then((result) => {
            if (result) {
                this.derivarOperacion()
            }
        });
    }

    isShowCavali() {
        const profiles = [this.authService.perfil.perfilJefeDeOperacionesID, this.authService.perfil.perfilAnalistaOperacionesID];

        if (!this.operacion || !profiles.includes(this.user.perfil)) return false;

        const estaciones = [this.operacion.estacionTesoreria, this.operacion.estacionAsistenteCobranzas, this.operacion.estacionJefeOperaciones];

        return (
            this.operacion?.operacion && (this.botonDerivar || this.operacion.actualAsistenteCobranzas || this.operacion?.estacion_devolucion) &&
            estaciones.includes(this.operacion.operacion.estacion)
        );
    }

    transferenciaCavali() {
        let detalles = this.operacion.detalle;
        detalles = detalles.filter(d => d.xml && d.estado == 3);

        if (detalles.length > 0) {
            detalles = detalles.filter(d => !d.transferencia_contable)
            if (detalles.length == 0) {
                this.toast.warning('Todas las facturas ya fueron transferidas')
                return
            }
            if (detalles.filter(d => !d.proceso_cavali).length == 0) {
                this.toast.warning('Las facturas se encuentran procesando por cavali')
                return
            }
            const data = {
                facturas: detalles.map(d => d.id),
            };
            let request = this.factoring.trasnferenciaCavali(data);

            request.then((resp:any) => {
                detalles.forEach(d => d.proceso_cavali = resp.id)
            }, err => {
                console.error(err);
            });
        } else {
            this.toast.warning('Debe haber al menos una factura registrada, verificada y con XML');
        }

    }

    reprogramacionCavali() {
        let detalles = this.operacionDetalles.filter(e => e.transferencia_contable && (e.vencimiento_cavali_rep || e.neto_pagar_rep) && !e.reprogramacion_cavali)
        if (detalles.length > 0) {
            const data = {
                facturas: detalles.map(e => e.id),
            }
            this.factoring.spinnerOn()
            this.factoring.reprogramacionCavali(data).subscribe(
                data => {
                    console.log(data)
                    this.spinnerOff('El proceso de reprogramación cavali inició correctamente')
                },
                res => {
                    let error = 'Ocurrió un error inesperado' + res
                    if (res.error && typeof(res.error) == 'object') {
                        error = Object.values(res.error).map(e => typeof(e) == 'object' ? JSON.stringify(e) : e).join('\n')
                    }
                    this.spinnerOff(error, false)
                }
            )
        } else {
            this.toast.warning('No hay facturas para reprogramar');
        }
    }

    get showButtomDetalle() {
        if (this.authService.user.isOficialDeNegocio && this.operacion.estacion != this.operacion.estacionOficialDeNegocio) {
            return false
        }

        if (this.operacion.actualOficialNegocioLiquidacion) {
            // console.log(!this.operacion.vuelta_adelanto);
            return !this.operacion.vuelta_adelanto;
        }

        return true;
    }

    get showReadonlyAccount() {
        return !((this.authService.user.isTesoreria && this.operacion.actualTesoreria) || this.authService.user.isLiderDeTesoreria) ||
            this.cabecera.solicitante_editar_cuenta_abono;
    }

    get deudoresRucs() {
        let detalles = this.operacion?.detalle
        let rucs = [...new Set(detalles?.map(e => e['deudor_ruc']))]
        if (rucs.toString() != this._deudoresRucs.toString()) {
            this._deudoresRucs = rucs
        }
        return this._deudoresRucs
    }

    receiveDeudaVencida(deuda) {
        this.tieneDeudaVencida = deuda['deuda_vencida']
    }

}
