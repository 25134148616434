import { Component, ViewChild, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { dateInput } from "app/shared/form-date/form-date.component";
import { ModalAlertasComponent } from "./modals/modal-alertas/modal-alertas.component";
import { ModalImportarComponent } from "./modals/modal-importar/modal-importar.component";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";

import { BaseLavadoActivosComponent } from "app/modules/lavado-activos/base-lavado-activos-component";

import * as ExcelJS from "exceljs";
import * as moment from "moment";

@Component({
	selector: "app-alertas",
	templateUrl: "./alertas.component.html",
	styleUrls: ["./alertas.component.css"],
})
export class AlertasComponent extends BaseLavadoActivosComponent {
	public requestService = (filters: Record<string, string>) => this.lavadoActivosService.obtenerPlantillasRiesgo(filters);
	// Utils - Iconos
	bsValue = new Date();
	// Utils - General
	public generando: boolean = false;
	public faltantes: any;
	public validando_str: string = "";
	public dateInput: dateInput;
	public isPeriodoButton: boolean = false;
	public isPeriodoButtonColor: any = "btn-primary";

	// Periodo
	public periodos: any[] = [];
	@Input() selectedPeriodo: any = {
		does_exist: false,
		cartera_mensual: 0,
		fecha: "",
		id: 0,
		is_open: false,
		plantillas_mensuales: 0,
		plantillas_pendientes: 0,
		promedio_mensual: 0
	};
	// Plantilla Riesgo
	public plantillaRiesgo: any;

	// Templates
	@ViewChild("e_confirmdelete", { static: false }) public e_confirmdelete;

	// Inicializamos Servicios y Modales
	constructor(public modalService: NgbModal, public authService: AuthService, private lavadoActivosService: LavadoActivosService) {
		super(authService, modalService);
	}

	// Lo primero que se ejecuta al entrar
	ngOnInit(): void {
		// Fecha de periodo por defecto
		this.setPeriodoFecha(this.getDate());
		this.buildInputDate();
	}

	getDate() {
		const year = this.bsValue.getFullYear();
		const month = this.bsValue.getMonth() + 1;
		const currentDate = `${year}-${month}-01`;
		return currentDate;
	}

	async setPeriodoFecha(date: any): Promise<void> {
		// Seteamos periodo_fecha por la fecha obtenida
		this.filters["periodo__fecha"] = date;
		try {
			const periodos = await this.lavadoActivosService.obtenerPeriodosSimple({ page_size: "1", page: "1", fecha: date });
			this.isPeriodoButton = periodos["results"]?.length === 0;
			this.isPeriodoButtonColor = this.isPeriodoButton ? "btn-secondary" : "btn-primary";

			const resultado = await Promise.all([periodos]);
			const periodo = resultado[0]["results"][0];

			this.setPeriodo(periodo);
			this.filters["periodo"] = periodo["id"];

			this.executeService(true); // Cargando datos desde la primera página para el periodo actual.
		} catch (error) {
			console.error("Periodo no encontrado");
			this.loadEmptyTable()
		}
	}

	async refrescarComponente() : Promise<void> {
		console.log("Refrescando");
		try {
			const periodos = await this.lavadoActivosService.obtenerPeriodosSimple({ page_size: "1", page: "1", fecha: this.filters["periodo__fecha"] });
			this.isPeriodoButton = periodos["results"]?.length === 0;
			this.isPeriodoButtonColor = this.isPeriodoButton ? "btn-secondary" : "btn-primary";
			
			const resultado = await Promise.all([periodos]);
			const periodo = resultado[0]["results"][0];

			this.setPeriodo(periodo);
			this.filters["periodo"] = periodo["id"];

			this.executeService(false); // Cargando datos manteniendo su paginación actual
		}catch(error) {
			console.error("Periodo no encontrado");
			this.loadEmptyTable()
		}
	}

	setPeriodo(periodo) {
		if(periodo){
			this.selectedPeriodo = {
				...periodo,
				does_exist: true,
				fecha: moment(periodo.fecha).format("MM-YYYY"),
			};
		}else{ // undefinned
			this.selectedPeriodo = {
				does_exist: false,
				cartera_mensual: 0,
				fecha: moment(this.filters["periodo__fecha"]).format("MM-YYYY"), //  ToDo: 2024-4-01
				id: 0,
				is_open: false,
				plantillas_mensuales: 0,
				plantillas_pendientes: 0,
				promedio_mensual: 0
			}
		}
	}

	async generarEvaluaciones() {
		this.faltantes = "Calculando...";
		this.validando_str = "Clientes";
		this.generando = true;

		let res = await this.lavadoActivosService.generarEvaluaciones(this.selectedPeriodo.id);

		this.faltantes = res["clientes_faltantes"];
		if (!res["generando"]) {
			this.generando = false;
		}

		this.executeService();
	}

	async cerrarPeriodo() {
		var is_error = false;
		let services = [
			await this.lavadoActivosService.validar_evaluaciones(this.selectedPeriodo.id),
			await this.lavadoActivosService.cerrarPeriodo(this.selectedPeriodo.id),
			await this.lavadoActivosService.generar_historial_evaluaciones(this.selectedPeriodo.id),
		];
		services.forEach((service) => {
			new Promise((resolve, rejects) => {
				if (is_error) {
					return false;
				}
				if (!is_error) {
					service
						.then((message: any) => {
							console.log(message);
							resolve(true);
						})
						.catch((err) => {
							is_error = true;
							rejects(false);
						});
				}
			});
		});
		this.setPeriodoFecha(this.filters["periodo__fecha"]);
	}

	exportar() {
		return new Promise<boolean>((resolve, rejects) => {
			this.lavadoActivosService
				.exportarCalificaciones(this.selectedPeriodo.id)
				.then((x: any) => {
					console.log("Generando Formato:");

					const { data } = x;
					const availableColumns = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ"]
					const workBook = new ExcelJS.Workbook();
					const workSheet = workBook.addWorksheet("formato");
					const cabeceraDataStyle = {
						alignement: {vertical: "middle", horizontal: "center"},
						font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 12, bold: false},
						fill: { type: "pattern", pattern: "solid", fgColor: {argb: "FAE5C3"} },
						border: {
							top: { style: 'thin', color: { argb: '757575' } },
							left: { style: 'thin', color: { argb: '757575' } },
							bottom: { style: 'thin', color: { argb: '757575' } }, 
							right: { style: 'thin', color: { argb: '757575' } } 
						}
					}
					const rowDataStyle = {
						alignement: {vertical: "middle", horizontal: "center"},
						font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: false},
						fill: { type: "pattern", pattern: "solid", fgColor: {argb: "FFFFFF"} },
						border: {
							top: { style: 'thin', color: { argb: '9c9c9c' } },
							left: { style: 'thin', color: { argb: '9c9c9c' } },
							bottom: { style: 'thin', color: { argb: '9c9c9c' } }, 
							right: { style: 'thin', color: { argb: '9c9c9c' } } 
						}
					}
					let currentRow = 1;
					let dynamicColumnWidths = []
				
					if(x.data.length == 0){
						console.log("Sin datos");
					}

					let cabecera  = Object.keys(data[0]);
					let dataDeploy = cabecera.map((colName = "", idx) => {
						dynamicColumnWidths.push(colName.length); // Default width for each column
						return {
							area: `${availableColumns[idx]}${currentRow}:${availableColumns[idx]}${currentRow}`,
							value: colName.replace(/_/g, " "),
							style: cabeceraDataStyle
						}
					})
					currentRow++;

					data.forEach(row => {
						cabecera.forEach((colName, idx) => {
							dataDeploy.push({
								area: `${availableColumns[idx]}${currentRow}:${availableColumns[idx]}${currentRow}`,
								value: row[colName],
								style: row[colName] != '' ? rowDataStyle : {...rowDataStyle, fill: {...rowDataStyle.fill, fgColor: {argb: "FAE5C3"}}}
							})
	
							if(dynamicColumnWidths[idx] < row[colName].length){
								dynamicColumnWidths[idx] = row[colName].length
							}
						})
						currentRow++;
					})

					dataDeploy.forEach(x => {
						workSheet.mergeCells(x.area);
						let tCell = workSheet.getCell(x.area)
						
						tCell.value = x.value;
						tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
						tCell.font = x.style.font;
						tCell.fill = x.style.fill as ExcelJS.Fill;
						tCell.border = x.style.border as Partial<ExcelJS.Borders>;
					})

					// Extra styles: 
					for(let i = 1; i <= data.length + 1; i++){
						workSheet.getRow(i).height = 16;
					}
					for(let i = 1; i <= cabecera.length; i++){
						workSheet.getColumn(i).width = dynamicColumnWidths[i-1] + 10;
					}

					// Generando buffer y disparando evento para descargar el archivo
					workBook.xlsx.writeBuffer().then(buffer => {
						const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
						const link = document.createElement('a');
						link.href = window.URL.createObjectURL(blob);
						link.download =  `LavadoActivos ${moment().format("DDMMYYYYHHMM")}.xlsx`;

						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					})
					
					resolve(true);
				})
				.catch((err) => {
					console.log(err);
					rejects(false);
				});
		});
	}

	importar() {
		// guardamos en la variable publica modalRef el servicio para crear modales
		this.modalRef = this.modalService.open(ModalImportarComponent, {
			ariaLabelledBy: "modal-basic-title", 
			size: "lg", 
			windowClass: "modalSizeXXL", 
		});

		this.modalRef.componentInstance.selectedPeriodo = this.selectedPeriodo;
		// Actualiza la tabla en cuanto el componente de importación conluye la subida del archivo.
		this.modalRef.componentInstance.importarCompleted.subscribe((completed : boolean) => {
			if(completed){
				this.executeService(true)
			}
		})
	}

	nuevoPeriodo() {
		this.lavadoActivosService
			.nuevoPeriodo({ fecha: this.filters["periodo__fecha"] })
			.then((res: any[]) => {
				this.isPeriodoButton = false;
				let periodos = res;
				this.periodos = periodos;
				this.setPeriodoFecha(this.filters["periodo__fecha"]);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	initVars() {
		// Inicializar columnas
		let columns = [
			{
				headerName: "N°",
				field: "index",
				sortable: false,
			},
			{
				headerName: "Cliente",
				class: "text-center",
				field: "cliente_nombre",
				sortable: true,
				filterable: true,
				filterProp: "cliente__cliente", // Nombre de la propiedad a filtrar
				filterInput: true, // Se puede filtrar?
			},
			{
				headerName: "Ruc",
				class: "text-center",
				field: "cliente_ruc",
				sortable: true,
				filterable: true,
				filterProp: "cliente__ruc", // Nombre de la propiedad a filtrar
				filterInput: true, // Se puede filtrar?
			},
			{
				headerName: "Plantilla",
				class: "text-center",
				field: "tipo_descripcion",
				sortable: true,
				filterable: true,
				filterProp: "tipo__descripcion", // Nombre de la propiedad a filtrar
				filterInput: true, // Se puede filtrar?
			},
			{
				headerName: "Nivel de Riesgo",
				class: "text-center fw-bold",
				field: "nivel_riesgo",
				sortable: false,
			},
			{
				headerName: "Puntación",
				class: "text-center fw-bold",
				field: "puntuacion_riesgo",
				sortable: true,
			},
			{
				headerName: "Eval. Pendientes",
				class: "text-center",
				field: "evaluaciones_pendientes",
				sortable: false,	
				pipe: 'functionInnertHTML',
				function: function (row) {
					if (row.evaluaciones_pendientes == 0){
						return `
							<div class="badge badge-primary px-3 py-1" style="font-size: 12px !important;">
								Completado
							</div>
						`
					} else {
						return `
							<div class="badge badge-dark px-3 py-1 position-relative" style="font-size: 12px !important;">
								Pendientes
								<div 
									class="badge badge-danger position-absolute top-50 start-0 translate-middle mx-1 px-2" 
									style="border: 1px solid blue !important; font-size: 10px !important;"
								>
									${row.evaluaciones_pendientes}
								</div>
								<span class="sr-only">unread messages</span>
							</div>
						`
					}
				}
			},
			{
				class: "text-center", 
				headerName: "Ultima Evaluación", 
				field: "ultima_evaluacion", 
				sortable: false,
			},
		];

		this.columnDefs = columns; // guardamos las columnas en la variable publica
	}

	addAlerta() {
		this.openModal(ModalAlertasComponent, {
			alerta: null, 
		});
	}

	editActionTable(row: any) {
		this.openModal(ModalAlertasComponent, {
			plantillaRiesgoId: row.id,
			is_open_periodo: this.selectedPeriodo.is_open,
		});
	}
	// Obtenemos el row
	deleteActionTable(row: any) {
		this.plantillaRiesgo = row;
		// Creamos el modal llamando el template e_confirmdelete
		this.modalRef = this.modalService.open(this.e_confirmdelete, {
			size: "md",
		});
	}
	// Si el usuario dice que si, llama al endpoint y elimina el objeto
	confirmDelete() {
		this.lavadoActivosService
			.deletePlantillaRiesgo(this.plantillaRiesgo.id)
			.catch((error) => {
				console.log(error);
			})
			.then(() => {
				this.modalRef.close();
				this.plantillaRiesgo = null;
				// Obtenmos las alertas
				this.executeService();
			});
	}

	rowColorAlerta(row: any): string {
		switch (row.nivel_riesgo) {
			case "Bajo":
				return "#e9fff3";
			case "Medio":
				return "#fff9c8";
			case "Alto":
				return "#ffd0cb";
			case "NO/ID":
				return "#ffa297";
			default:
				return "#FFFFFF";
		}
	}
	// Cerrar el modal
	closeModal() {
		this.modalRef.close();
		this.plantillaRiesgo = null;
	}

	// Paginador
	async goToPage({ page, per_page, filtros }): Promise<void> {
		this.filters = {
			...this.filters,
			page: page,
			page_size: per_page,
		};

		let campos = ["tipo__descripcion", "cliente__cliente", "cliente__ruc"];
		campos.forEach((campo) => {
			if (campo in filtros) {
				this.filters[campo] = filtros[campo];
			}
		});
		try {
			this.executeService();
		} catch (error) {
			// Manejar el error aquí
			console.error("Error al obtener las plantillas de riesgo:", error);
		}
	}

	buildInputDate() {
		this.dateInput = {
			bsConfig: {
				defaultDay: "01",
				minMode: "month",
				adaptivePosition: true,
				dateInputFormat: "MM-YYYY",
			},
			bsplaceholder: "00/0000",
			classes: "",
		};
	}
}
