import { Component, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { FormBuilder } from "@angular/forms";
import { RecaudacionService } from "app/core/services/factoring/recaudacion.service";
import { functions } from "../../../../../../core/helpers/functions";
import { emit } from "process";
@Component({
	selector: "app-modal-importar",
	templateUrl: "./modal-importar.component.html",
	styleUrls: ["./modal-importar.component.css"],
})
export class ModalImportarComponent implements OnInit {
	@Output() importarCompleted = new EventEmitter<boolean>();
	// Utils - Iconos
	faTimes = faTimes;
	public rowData: any[] = [];
	public loadingTable: boolean = false;
	public columnDefs: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
	public searchstring: string = "";
	public columns: any;
	public previewExcel: boolean = false;
	public excelFile: File;
	public formData = new FormData();
	public selectedPeriodo: any;

	constructor(
		private formBuilder: FormBuilder,
		public activeModal: NgbActiveModal,
		private recaudacionService: RecaudacionService,
		private lavadoActivosService: LavadoActivosService
	) {}

	ngOnInit(): void {}

	async onFileExcel(event) {
		this.excelFile = event.target.files[0];
		this.formData.append("archivo", this.excelFile);
		this.previewExcel = true;
	}

	async confirmarImportacion() {
		this.formData.append("archivo", this.excelFile);
		await this.lavadoActivosService.postExcelImportacion(this.selectedPeriodo.id, this.formData);
		this.importarCompleted.emit(true);
		this.activeModal.close(false);
	}

    cancelarImportacion(){
		this.importarCompleted.emit(false);
		this.activeModal.close(false);
    }
}
