import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { faChartPie, faCloudDownloadAlt, faTimes, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FormControl, FormGroup, Validators, FormBuilder, ControlContainer } from '@angular/forms';
export interface dateInput {
  bsConfig : {
    defaultDay: string,
    minMode: string,
    adaptivePosition: boolean,
    dateInputFormat: string
  },
  bsplaceholder: string,
  classes: string
}
@Component({
  selector: 'app-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.css']
})
export class FormDateComponent implements OnInit {
  // VARIABLES 
  bsValue = new Date();
  public _name: string = '';

  @Input() dateInput : dateInput
  @Output() sendtDate = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit(): void {}

  // Obtenemos los cambios realizados en el input date
  changeDate(e: Date) {
    // Declaramos la constante date con los datos seleccionados 
    var date

    // Verificamos la configuración de fecha obtenida
    switch (this.dateInput.bsConfig['minMode']) {
      case 'day':
        // Declaramos date con el día elegido
        date = `${e.getFullYear()}-${e.getMonth() + 1}-${e.getUTCDate()}`;
      break;
      case 'month':
        // Declaramos date con el mes elegido
        date = `${e.getFullYear()}-${e.getMonth() + 1}-${this.dateInput.bsConfig['defaultDay']}`;
      break;
      case 'year':
        // Declaramos date con el año elegido
        date = `${e.getFullYear()}`;
      break;
  }
  
    // Retornamos la fecha obtenida
    this.sendtDate.emit(date);
  }
}
