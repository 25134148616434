import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { DocschecklistService } from "app/core/services/config/docschecklist.service";
import { DocsModalComponent } from "app/modules/aplicacion-evaluacion-riesgos/modals/docs-modal/docs-modal.component";
import { VerificationsService } from "app/core/services/factoring/verifications.service";
import { FactoringService } from "app/core/services/factoring/factoring.service";
import { ToastService } from "app/core/services/toast/toast.service";
import { MultiVerificactionModalComponent } from "../modals/operaciones/multi-verificaction-modal/multi-verificaction-modal.component";
import { AuthService } from "app/core/services/auth/auth.service";
import * as XLSX from 'xlsx';
import { Operacion } from "app/core/services/auth/Operacion";
import { fingerprint } from "@angular/compiler/src/i18n/digest";
import * as XMLtoJson from "xml-js";
import { DomSanitizer } from "@angular/platform-browser";
import { ResultadoCavaliComponent } from "../resultado-cavali/resultado-cavali.component";
import { faChartPie, faPlus, faSyncAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { PreviewFileService } from "app/core/services/file/preview-file.service";
@Component({
  selector: "app-form-doc-solicitud-factoring",
  templateUrl: "./form-doc-solicitud-factoring.component.html",
  styleUrls: ["./form-doc-solicitud-factoring.component.css"],
})
export class FormDocSolicitudFactoringComponent implements OnInit {
  faTimes = faTimes;
   faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  public minDate: Date;
  public f_det: FormGroup;
  public estados_list: any[] = [];
  public tipodoc_list: any[] = [];
  public monedas_list: any[] = [];
  public columnDefs: any[] = [];
  public tableToggle: boolean = true;
  public editableForm: boolean = false;
  public currency: any;
  public currencyBanco: any;
  @Input() set monedaBanco(value) {
    if (!!value) {
      this.currencyBanco = value
    }
  }
  
  
  get monedaBanco(){
    return this.currencyBanco
  }
  @Output() canalEmit: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() canal: any;
  @Input() readonly: boolean = false;
  @Input() importXmlButtom: boolean = true;
  @Output() detalles: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() tableData: any[] = [];
  _step2: boolean = false;
  @Input() set step2(val) {
    this._step2 = val;
  }
  @Input() operacion: Operacion;
  @Input() clienteFactoring: any;
  
  get step2() {
    return this._step2;
  }
  
  public columnDefsComments: any[];
  public loadingTable: boolean = false;
  public comments: any[] = [];
  tblAcciones = []
  
  @Output() xmlEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshTable: EventEmitter<any> = new EventEmitter<any>();
  
  xmlFiles: File[] = [];
  
  user: any;
  participantes = []

  constructor(
    public formbuild: FormBuilder,
    public modalService: NgbModal,
    public localeService: BsLocaleService,
    public configs: DocschecklistService,
    private verificacionService: VerificationsService,
    private factoring: FactoringService,
    public toast: ToastService,
    public authService: AuthService,
    public previewFileService: PreviewFileService,
    private sanitized: DomSanitizer
    ) {
      defineLocale("es", esLocale);
      this.localeService.use("es");
      this.user = this.authService.user;
    }
    
  ngOnInit(): void {
    this.initForm();
    this.loadTableComments();

    let participantes = [
        {'ruc': this.operacion.beneficiario_.ruc, 'nombre': this.operacion.beneficiario_.nombre}
      ].concat(
        this.operacion.detalle.map(e => ({'ruc': e['deudor_ruc'], 'nombre': e['deudor_nombre']}))
      )
    participantes = [...new Set(participantes.map(e => JSON.stringify(e)))].map(e => JSON.parse(e))
    this.participantes = participantes
  }

  initForm() {
    this.configs.obtenerMonedas().then((res) => {
      this.monedas_list = res["results"];
    });

    this.configs.obtenerTipoDetalles().then((res) => {
      this.tipodoc_list = res["results"].sort(function (a, b) {
        if (a.descripcion > b.descripcion) {
          return 1;
        }
        if (a.descripcion < b.descripcion) {
          return -1;
        }
        return 0;
      });;
    });

    this.verificacionService.obtenerEstados().then((result) => {
      this.estados_list = result["results"];
    });

    if (this.step2) {
      this.columnDefs = [
        {
          headerName: "N°",
          pipe: "indexcol",
          sortable: true,
        },
        {
          headerName: "Aceptante",
          field: "aceptante",
          sortable: true,
          pipe: "functionInnertHTML",
          function: (row) => {      
            if (!row.tiene_resumen) {
              return this.sanitized.bypassSecurityTrustHtml(`${row.aceptante} <button class="btn btn-danger btn-round btn-sm" style="font-size: 0.7em; padding: .2em .8em;">Nuevo</button>`);
            } else {
              return row.aceptante;
            }
          },
        },
        {
          headerName: "RUC o DNI",
          field: "ruc",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "Tipo de Documento",
          field: "tipo_detalle_descripcion",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "Número de Documento",
          field: "numero_documento",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "Moneda",
          field: "moneda_descripcion",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "Monto",
          field: "monto_total",
          sortable: true,
          moneda: "moneda",
          pipe: "currency",
          class: "text-center",
        },
        {
          headerName: "XML",
          field: "xml",
          sortable: true,
          class: "text-center",
          pipe: "buttonElement",
          function: function (row) {
            if (row.xml && row.operacion_xml) {
              return 'Si';
            } else {
              return "No";
            }
          },
        },
        // {
        //   headerName: "PDF",
        //   field: "adjunto_pdf",
        //   sortable: true,
        //   class: "text-center",
        //   pipe: "functionInnertHTML",
        //   function: function (row) {
        //     if (row.adjunto_pdf) {
        //       const link = row.adjunto_pdf;
        //       return `<a href="${link}" download="newname.pdf">Si</a>`;
        //     } else {
        //       return "No";
        //     }
        //   },
        // },
        {
          headerName: "PDF",
          field: "adjunto_pdf",
          sortable: true,
          class: "text-center",
          pipe: "buttonElement",
          actionableType: 'pdf',
          function: function (row) {
            if (row.adjunto_pdf) {
              return 'Si';
            } else {
              return "No";
            }
          },
        },
      ];

      this.f_det = this.formbuild.group({
        aceptante: [null, [Validators.required]],
        ruc: [null, [Validators.required]],
        tipo_detalle: [null, [Validators.required]],
        numero_documento: [null, [Validators.required]],
        vencimiento: [null, [Validators.required]],
        monto_total: [null, [Validators.required]],
        estado: [null, [Validators.required]],
        moneda: [null, [Validators.required]],
      });
    } else {
      this.columnDefs = [
        {
          headerName: "N°",
          pipe: "indexcol",
          sortable: true,
        },
        {
          headerName: "Nombre o Razón Social",
          field: "aceptante",
          sortable: true,
          pipe: "functionInnertHTML",
          function: (row) => {      
            if (!row.tiene_resumen) {
              return this.sanitized.bypassSecurityTrustHtml(`${row.aceptante} <button class="btn btn-danger btn-round btn-sm" style="font-size: 0.7em; padding: .2em .8em;">Nuevo</button>`);
            } else {
              return row.aceptante;
            }
          },
        },
        {
          headerName: "RUC o DNI",
          field: "ruc",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "Reg.Cavali",
          field: "aceptante_registrado_cavali",
          sortable: false,
          class: "text-center",
          pipe: "function",
          function: ({'aceptante_registrado_cavali': value}) => value === 1 ? 'Si' : (value === 0 ? 'No' : ' ')
        },
        {
          headerName: "Detracción",
          field: "det",
          sortable: true,
          checkDisabled: true,
          pipe: "checkbox",
          class: "text-center",
        },
        {
          headerName: "Retención",
          checkDisabled: true,
          field: "ret",
          sortable: true,
          pipe: "checkbox",
          class: "text-center",
        },
        {
          headerName: "Aval",
          checkDisabled: true,
          field: "aval",
          sortable: true,
          pipe: "checkbox",
          class: "text-center",
        },
        // {
        //   headerName: "Físico",
        //   checkDisabled: true,
        //   field: "fisico",
        //   sortable: true,
        //   pipe: "checkbox",
        //   class: "text-center",
        // },
        {
          headerName: "Tipo de Documento",
          field: "tipo_detalle_descripcion",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "Número de Documento",
          field: "numero_documento",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "Vencimiento",
          field: "vencimiento",
          sortable: true,
          class: "text-center",
          pipe: "date",
        },
        {
          headerName: "Moneda",
          field: "moneda_descripcion",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "Monto",
          field: "monto_total",
          sortable: true,
          moneda: "moneda",
          pipe: "currency",
          class: "text-center",
        },
        {
          headerName: "Vencimiento CAVALI",
          field: "vencimiento_cavali",
          sortable: true,
          class: "text-center",
          pipe: "date",
        },
        {
          headerName: "Monto Neto",
          field: "neto_pagar",
          sortable: true,
          moneda: "moneda",
          pipe: "currency",
          class: "text-center",
        },
        {
          headerName: "Estado",
          field: "estado_descripcion",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "Estado CAVALI",
          field: "estado_cavali",
          sortable: true,
          class: "text-center",
          pipe: "buttonElement",
          function: function (row) {
            if (row.estado_cavali) {
              return `${row.estado_cavali_descripcion}`;
            }
          },
        },
        {
          headerName: "Respuesta adquiriente",
          field: "respuesta_adquiriente_descripcion",
          sortable: false,
          class: "text-center",
        },
        {
          headerName: "Contacto (Deudor)",
          field: "contacto_deudor",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "Télefono (Deudor)",
          field: "telefono_deudor",
          sortable: true,
          class: "text-center",
        },
        {
          headerName: "XML",
          field: "xml",
          sortable: true,
          class: "text-center",
          pipe: "buttonElement",
          function: function (row) {
            if (row.xml && row.operacion_xml) {
              return 'Si';
            } else {
              return "No";
            }
          },
        },
        // PDF
        {
          headerName: "PDF",
          field: "adjunto_pdf",
          sortable: true,
          class: "text-center",
          pipe: "buttonElement",
          actionableType: 'pdf',
          function: function (row) {
            if (row.adjunto_pdf) {
              return 'Si';
            } else {
              return "No";
            }
          },
        },
        // {
        //   headerName: "PDF",
        //   field: "adjunto_pdf",
        //   sortable: true,
        //   class: "text-center",
        //   pipe: "functionInnertHTML",
        //   downloadFile: true,
        //   function: function (row) {
        //     if (row.adjunto_pdf) {
        //       const link = row.adjunto_pdf;
        //       // return `<a href="#" download="newname.pdf">Si</a>`;
        //       return `<a style="color: blue">Si</a>`;
        //     } else {
        //       return "No";
        //     }
        //   },
        // },
        {
          headerName: "Inválido",
          field: "invalido",
          sortable: true,
          class: "text-center",
          pipe: "checkbox",
          checkDisabled: true,
        },
      ];

      this.f_det = this.formbuild.group({
        aceptante: [null, [Validators.required]],
        ruc: [null, [Validators.required]],
        det: [false, [Validators.required]],
        ret: [false, [Validators.required]],
        aval: [false, [Validators.required]],
        tipo_detalle: [null, [Validators.required]],
        numero_documento: [null, [Validators.required]],
        contacto_deudor: [null, []],
        telefono_deudor: [null, []],
        vencimiento: [null, [Validators.required]],
        monto_total: [null, [Validators.nullValidator]],
        estado: [null, [Validators.required]],
        moneda: [null, [Validators.required]],
      });
    }

    this.f_det.valueChanges.subscribe((value) => {
      if (value.moneda) {
        this.currency = {
          prefix: value.moneda === 1 ? "S/ " : "$ ",
          thousands: ".",
          decimal: ",",
        };
      }
    });

    // this.estados_list = [
    //     {
    //         id: 1,
    //         descripcion: 'Registrado'
    //     }
    // ]

    this.minDate = new Date();

    this.tblAcciones = [
      {
        tooltip: 'Ver pdf', icon: 'fa fa-file-pdf-o', class: 'btn-outline-danger',
        click: row => this.previewFile(row.adjunto_pdf),
      },
    ]
  }

  agregarDetalle(modal) {
    this.modalService
      .open(modal, {
        size: "lg",
      })
      .result.then(
        (result) => {
          if (result) {
            this.canalEmit.emit(this.formatoYAgregaDocumento(this.f_det.value));
          }
          this.f_det.reset();
        },
        (reason) => {
          this.f_det.reset();
        }
      );
  }

  openModalDetalles() {
    let moneda = !!this.monedaBanco ? this.monedaBanco : this.operacion.moneda_banco
    this.f_det.get('moneda').setValue(moneda)
    const modalRef = this.modalService.open(DocsModalComponent, {
      size: "lg",
    });
   
    modalRef.componentInstance.operacion = this.operacion;
    modalRef.componentInstance.step2 = this.step2;
    modalRef.componentInstance.readonly = false;
    modalRef.componentInstance.monedaDelBanco = !!this.monedaBanco ? this.monedaBanco : this.operacion.moneda_banco;
    modalRef.componentInstance.clienteFactoring = this.clienteFactoring;

    modalRef.componentInstance.docsEmit.subscribe(
      (result) => {
        if (result) {
          let data = this.formatoYAgregaDocumento(result);
          console.log(data);
          this.canalEmit.emit(data);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  formatDate(date) {
    if (!(date instanceof Date)) return;
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  editarDocumentoDeTable(row, modal) {
    this.editableForm = true;
    this.f_det.reset({
      aceptante: row.aceptante,
      ruc: row.ruc,
      det: row.det,
      ret: row.ret,
      aval: row.aval,
      moneda: row.moneda,
      tipo_detalle: row.tipo_detalle,
      numero_documento: row.numero_documento,
      contacto_deudor: row.contacto_deudor,
      telefono_deudor: row.telefono_deudor,
      vencimiento: new Date(row.vencimiento),
      monto_total: row.monto_total,
      estado: row.estado,
    });
    this.modalService
      .open(modal, {
        size: "lg",
      })
      .result.then(
        (result) => {
          if (result) {
            let value = this.formatoYAgregaDocumento(this.f_det.value);
            value.id = row.id;
            console.log(value);
            this.canalEmit.emit(value);
          }
          this.editableForm = false;
          this.f_det.reset();
        },
        (reason) => {
          this.editableForm = false;
          this.f_det.reset();
        }
      );
  }

  showDocModal(row) {
    const modalRef = this.modalService.open(DocsModalComponent, {
      size: "lg",
    });

    modalRef.componentInstance.operacion = this.operacion;
    modalRef.componentInstance.row = row;
    modalRef.componentInstance.readonly = true;
    modalRef.componentInstance.clienteFactoring = this.clienteFactoring;

    modalRef.result.then(
      (result) => {
        modalRef.componentInstance.readonly = false;
        console.log(result);
      },
      (reason) => {
        console.log("reason");
      }
    );
  }

  editarDocsModal(row) {
    const modalRef = this.modalService.open(DocsModalComponent, {
      size: "lg",
    });

    modalRef.componentInstance.operacion = this.operacion;
    modalRef.componentInstance.row = row;

    modalRef.componentInstance.step2 = this.step2;
    modalRef.componentInstance.clienteFactoring = this.clienteFactoring;


    console.log(this.operacion, this.authService.user)

    modalRef.componentInstance.docsEmit.subscribe(
      (result) => {
        if (result) {
          console.log(result);
          let value = this.formatoYAgregaDocumento(result);
          // value.id = row.id;
          result.id = row.id;
          // result['vencimiento'] = this.formatDate(result['vencimientox']);
          result["vencimiento"] = result["vencimientox"];
          // result['vencimiento_cavali'] = this.formatDate(result['vencimiento_cavali']);
          result["vencimiento_cavali"] = result["vencimiento_cavalix"];
          this.canalEmit.emit(result);
        }
      },
      (error) => {
        console.error(error);
      }
    );

    modalRef.componentInstance.xmlEmit.subscribe((result) => {
      if (result) {
        this.sendXml(result);
      }
    });

    modalRef.componentInstance.pdfEmit.subscribe((result) => {
      if (result) {
        this.sendPdf(result, row["id"]);
      }
    });

    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {
        console.log("reason");
      }
    );
  }

  eliminarDocumentoDeTabla(row, modal) {
    console.log(row);

    if (this.operacion.isDenegado || this.operacion.isCancelado) {
      this.modalService
        .open(modal, {
          size: "md",
        })
        .result.then(
          (result) => {
            if (result) {
              row.invalido = !row.invalido;
              this.canalEmit.emit(row);
            }
            this.f_det.reset();
          },
          (reason) => {
            this.f_det.reset();
          }
        );
    } else {
      this.modalService
        .open(modal, {
          size: "md",
        })
        .result.then(
          (result) => {
            if (result) {
              row.del = true;
              this.canalEmit.emit(row);
            }
            this.f_det.reset();
          },
          (reason) => {
            this.f_det.reset();
          }
        );
    }
  }

  formatoYAgregaDocumento(doc) {
    let value = doc;
    value.estado_name = this.estados_list.find(
      (e) => e.id === doc.estado
    ).descripcion;
    value.tipo_doc_name = this.tipodoc_list.find(
      (e) => e.id === doc.tipo_detalle
    ).descripcion;

    let moneda = !!this.monedaBanco ? this.monedaBanco : this.operacion.moneda_banco

    value.moneda_nombre = this.monedas_list.find(
      (e) => e.id === moneda
    ).descripcion;


    value.moneda_descripcion = this.monedas_list.find(
      (e) => e.id === moneda
    ).descripcion;

    value.vencimiento = this.formatDate(value.vencimiento);

    value.tipo_detalle_descripcion = this.tipodoc_list.find(
      (e) => e.id === doc.tipo_detalle
    ).descripcion;
    if (value.vencimiento_cavali)
      value.vencimiento_cavali = this.formatDate(value.vencimiento_cavali);
    return value;
  }

  loadTableComments() {
    this.columnDefsComments = [
      {
        headerName: "N°",
        pipe: "indexcol",
        sortable: true,
      },
      {
        headerName: "Comentario",
        field: "comment",
        sortable: true,
      },
      {
        headerName: "Responsable",
        field: "responsable",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Fecha",
        field: "date",
        sortable: true,
        class: "text-center",
      },
    ];
  }

  editComment(commet) { }

  deleteComment(commet) { }

  importXML() {
    document.getElementsByName("xml")[0].click();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      // Validar sea XML
      let isXML = true;

      for (let index = 0; index < event.target.files.length; index++) {
        if (this.validTypeFile(event.target.files[index])) {
          isXML = false;
        }
      }

      if (!isXML) {
        this.toast.warning("El formato de todos los documentos no es XML");
        return;
      }

      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("adjunto", file);

      let fileReader = new FileReader();
      fileReader.onload = (e) => {

        if ((typeof fileReader.result == 'string') && (this.readXML(fileReader.result))) {
          this.xmlEmit.emit(event.target.files);
        }

      }
      fileReader.readAsText(file);
    }
  }

  readXML(resultados: string) {
    let convert = require('xml-js');
    let options = { compact: true };
    let OBJETO = JSON.parse(convert.xml2json(resultados, options));
    let monedaXML = OBJETO.Invoice['cbc:DocumentCurrencyCode']['_text']
    let xmlMonedaAccess;

    if (this.clienteFactoring) {
      let rucClienteXML = OBJETO.Invoice["cac:AccountingCustomerParty"]["cac:Party"]["cac:PartyIdentification"]["cbc:ID"]?._text
      if (this.clienteFactoring.ruc != rucClienteXML) {
        let ruc_cliente = OBJETO.Invoice["cac:AccountingCustomerParty"]["cac:Party"]["cac:PartyIdentification"]["cbc:ID"]?._text
        let nombre_cliente = OBJETO.Invoice["cac:AccountingCustomerParty"]["cac:Party"]["cac:PartyLegalEntity"]["cbc:RegistrationName"]?._cdata
        let mensaje = `El Aceptante del XML que intenta adjuntar ${nombre_cliente} (${ruc_cliente}) no coincide con el Cliente que seleccionó previamente..`
        this.toast.warning(mensaje)
        return false
      }
    }

    if (!!this.monedaBanco) {
      xmlMonedaAccess = this.allowXML(this.monedaBanco, monedaXML)
    } else {
      xmlMonedaAccess = this.allowXML(this.operacion.moneda_banco, monedaXML)
    }

    if (xmlMonedaAccess) {
      return true
    } else {
      let mensaje = 'El detalle que intenta adjuntar no corresponde a la moneda de abono seleccionada.'
      this.toast.warning(mensaje)
      return false
    }

  }

  allowXML(monedaBanco, monedaXML) {
    if (monedaBanco == 1 && monedaXML == 'PEN') {
      return true
    }

    if (monedaBanco == 2 && monedaXML == 'USD') {
      return true
    }

    return false

  }

  validTypeFile(file) {
    const type = file.type.split("/")[1];
    return (type != "xml") ? true : false;
  }

  sendXml(data: FormData) {
    let dataArray = [];
    dataArray.push(data);

    this.factoring.importarXmlDetalles(dataArray)
      .then((result) => {
        let detalleId = data.get('operacion_detalle');
        this.cargarDocumento(detalleId);
        this.refresh();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  sendPdf(data: FormData, id) {

    this.factoring
      .importarPDF(id, data)
      .then((result) => {
        // this.cargarDocumento(result["id"]);
        this.refresh();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  sendDetalles(data) {
    this.factoring.guardarDetalle(data)
      .then(res => {
        this.cargarDocumento(res["id"]);
        this.refresh();
      })
      .catch(error => {
        this.displayErrors(error);
        console.error(error);
      });
  }

  displayErrors(err){
    if(err && err.error){
        for(let prop in err.error){
            this.toast.warning(err.error[prop])
        }
    }
}

  cargarDocumento(detalle) {
    this.factoring.obtenerDetalle(detalle).then((res) => {
      setTimeout(() => {
        this.editarDocsModal(res);
        // this.canalEmit.emit(null);
      }, 200);
    });
  }

  refresh() {
    this.refreshTable.emit();
  }

  get isExistDetallesStateRegister() {
    const estadosPermitidos = [1, 2];
    const listDetalles = this.tableData.filter(item => estadosPermitidos.includes(item.estado));

    const perfilesPermitidos = [
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilOficialDeNegocioID,
      this.authService.perfil.perfilLiderDeEquipo,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilAsistenteComercialID,
    ];

    if (this.operacion.estacion == 1)
      return false;

    // if (!listDetalles.length)
    //   return false;

    if (perfilesPermitidos.includes(this.user.perfil)) {
      return true;
    } else {
      false;
    }

  }

  verificarDocs() {
    const modalRef = this.modalService.open(MultiVerificactionModalComponent, {
      size: 'xl',
      windowClass: "modalSizeXXL"
    });
    modalRef.componentInstance.tableData = this.tableData;
    modalRef.componentInstance.estados_list = this.estados_list;
    modalRef.componentInstance.operacionId = this.operacion.id;
    modalRef.componentInstance.detalles = this.tableData.filter(item => item.estado == 1 || item.estado == 2 || item.estado == 3);
    modalRef.componentInstance.clienteFactoring = this.clienteFactoring;

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {

      },
      (error) => {
        console.error(error);
      }
    );

    modalRef.result.then(
      (result) => {
        if (result) {
          this.refresh();
        }
      },
      (reason) => {
      }
    );

  }

  get showButtomExcel() {
    if (this.authService.user.isOficialDeNegocio && this.operacion.estacion != this.operacion.estacionOficialDeNegocio) {
      return false
    }

    const perfilesPermitidos = [
      this.authService.perfil.perfilOficialDeNegocioID,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilLiderDeEquipo,
      this.authService.perfil.perfilAsistenteComercialID,
    ];

    const estaciones = [
      this.operacion.estacionOficialDeNegocio,
      this.operacion.estacionAnalisisRiesgo,
      this.operacion.estacionComiteCreditos,
      this.operacion.estacionOficialNegocioPostComite,
      this.operacion.estacionOficialNegocioLiquidacion,
      this.operacion.estacionLegal
    ];

    return ((this.authService.user.isGerenteComercial || this.authService.user.isGerenteGeneral) && this.operacion.estacionJefeOperaciones) ? true : (perfilesPermitidos.includes(this.authService.user.perfil) && estaciones.includes(this.operacion.estacion));
  }

  importEXCEL() {
    document.getElementsByName("excel")[0].click();
  }

  onFileChange(ev) {
    console.log('Cargo e excel');
    try {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      if (!ev.target.files.length) return;
      const file = ev.target.files[0];
      const type = file.name.split('.')[1];
      if (type != 'xlsx') {
        this.toast.warning('El archivo no es Excel');
        return;
      };
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        const dataString = JSON.stringify(jsonData);
        // document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
        // console.log(dataString);
        const key = Object.keys(jsonData)[0];
        this.armarArray(jsonData[key]);
        // this.setDownload(dataString);
      }
      reader.readAsBinaryString(file);
    } catch (error) {
      console.error(error);

    }
    (<HTMLInputElement>document.getElementsByName("excel")[0]).value = null
  }

  armarArray(dataExcel) {
    let arrayData = [];
    dataExcel.forEach(item => {
      const factura = this.tableData.find(d => d['numero_documento'].includes(item['NUMERACION']));
      const data = { ...factura };
      if (factura) {
        (item['FECHA_VENCIMIENTO']) ? data['vencimiento'] = this.formatoFecha(item['FECHA_VENCIMIENTO']) : '';
        (item['FECHA_PAGO']) ? data['vencimiento_cavali'] = this.formatoFecha(item['FECHA_PAGO']) : '';
        (item['IMPORTE_NETO_PENDIENTE_PAGO']) ? data['neto_pagar'] = item['IMPORTE_NETO_PENDIENTE_PAGO'] : '';
        data['operacion'] = this.operacion.id;
        delete data['operacion_xml'];
        delete data['adjunto_pdf'];
        arrayData.push(data);
      }
    });

    if (!arrayData.length) {
      this.toast.warning('No se encontraron facturas relacionadas');
      return;
    }

    this.verificacionService.verificarDetalles(arrayData)
      .then(res => {
        const message = `Se actualizaron ${arrayData.length}/${this.tableData.length} facturas`;
        this.refresh();
        this.toast.success(message);
      }, err => {

      });
  }

  formatoFecha(fecha) {
    if (typeof (fecha) == "number") {
      return this.toast.warning("Una fecha de Excel no se actualizo, debe poseer formato de texto siguiendo la regla DD/MM/YYYY.")
    }
    const fechaArray = fecha.split('/');
    return `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;
  }

  canReadDetallesDocument() {
    // Si la operacion esta devuelta a un Oficial de negocios
    if (!this.readonly
      && this.authService.user.isOficialDeNegocio
      && [this.operacion.estacionAnalisisRiesgo,
      this.operacion.estacionLegal,
      this.operacion.estacionJefeOperaciones]
        .indexOf(this.operacion.estacion_devolucion) != -1) {
      return (doc) => doc.estado === 3 // Si esta verificado
    }
    return (doc) => true

  }

  canEditDetallesDocument() {
    // Si la operacion esta devuelta a un Oficial de negocios
    if (!this.readonly
      && this.authService.user.isOficialDeNegocio
      && [this.operacion.estacionAnalisisRiesgo,
      this.operacion.estacionLegal,
      this.operacion.estacionJefeOperaciones]
        .indexOf(this.operacion.estacion_devolucion) != -1) {
      return (doc) => doc.estado !== 3  // Si no esta verificado
    }
    return (doc) => true
  }

  canDeleteDetallesDocument() {
    // Perfil de consulta no puede
    if (this.authService.user.isConsultas) {
      return (doc) => false
    }

    // Si la operacion esta Liquidada
    if(this.operacion.isLiquidado){
      return (doc) => false
    }

    // Si la operacion esta cancelada o denegada
    if (this.operacion.isDenegado || this.operacion.isCancelado) {
      return (doc) => true
    }

    // Si la operacion esta devuelta a un Oficial de negocios
    else if (!this.readonly
      && this.authService.user.isOficialDeNegocio
      && [this.operacion.estacionAnalisisRiesgo,
      this.operacion.estacionLegal,
      this.operacion.estacionJefeOperaciones]
        .indexOf(this.operacion.estacion_devolucion) != -1) {
      return (doc) => doc.estado !== 3  // Si no esta verificado
    }

    return (doc) => true
  }

  readActionFunctionPriorize() {
    // Si la operacion esta devuelta a un Oficial de negocios
    if (!this.readonly
      && this.authService.user.isOficialDeNegocio
      && [this.operacion.estacionAnalisisRiesgo,
      this.operacion.estacionLegal,
      this.operacion.estacionJefeOperaciones]
        .indexOf(this.operacion.estacion_devolucion) != -1) {
      return true
    }
    return false
  }

  deleteActionFunctionPriorize() {
    // Si la operacion esta devuelta a un Oficial de negocios
    if (this.operacion.isDenegado || this.operacion.isCancelado) {
      return true
    }

    if(this.operacion.estacionAnalisisRiesgo && this.authService.user.id == this.operacion.oficial_negocio && this.authService.user.isOficialDeNegocio){
      return true
    }

    return false
  }

  buttonDownloadClick(event) {
    console.log(event);

    if (event.field == 'adjunto_pdf') {
      this.downloadFile(event.adjunto_pdf);
      return;
    } else if (event.field == 'xml') {
      let urlDoc = event.operacion_xml[0].adjunto;
      const ultimoSlash = new URL(urlDoc).pathname;
      const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1]
      this.downloadFile(urlDoc, adjuntoName);
      // this.getFileFromLink(urlDoc);
    } else if (event.field == 'estado_cavali') {
      const cavaliModal = this.modalService.open(ResultadoCavaliComponent, {size: "xl",})
      cavaliModal.componentInstance.operacionDetalleId = event.id
    }
  }

  getFileFromLink(link) {
    let nameFile = link.split('/').reverse()

    fetch(link, {
      method: 'GET',
      credentials: 'same-origin',
      referrerPolicy: 'no-referrer',
      headers: {
      },
    }).then((res: any) => {
      return res.text();
    }).then((res) => {
      var blob = new Blob([res]);
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = nameFile[0];
      link.click();
    })
      .catch((err) => {
        console.log(err);
      });
  }

  downloadFile(url, name = 'adjunto.pdf') {
    this.factoring.downloadFile(url).then(
      (x: any) => {
        console.log(x);
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
      }, async (reason) => {
        console.log(reason);
      }), err => {
        console.error(err);
      };
  }

  previewFile(url) {
    this.factoring.downloadFile(url).then(
      (data: any) => {
        this.previewFileService.open(data)
      }, (reason) => {
        console.log(reason);
      })
  }

}
